<template>
	<div>
		<ByCancel @close="close"></ByCancel>
		<h3 class="title">隱私權政策</h3>
		<div class="content"></div>
		<ByFooter />
	</div>
</template>

<script>
export default {
	methods: {
		close() {
			this.$router.go(-1)
		},
	},
}
</script>

<style lang="sass" scoped>
.title
  position: absolute
  top: -14px
  left: 50%
  transform: translateX(-50%)
  font-size: 18px

.content
  +fc
  flex-direction: column
  padding: 0 17px 30px
  margin-top: 15px
  width: 100%
  letter-spacing: 1px
  font-size: 15px
  line-height: 1.5
  text-align: justify

> p
  width: 100%

.part
  margin: 25px 0 0
</style>
